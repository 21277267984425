import { Controller } from "stimulus";
import Rails from "rails-ujs";

export default class extends Controller {
  static targets = ["checkAllBtn", "notification"];

  checkAllBtnText = "";

  _checkNotification = notification => {
    notification.classList.remove("unchecked");
    notification.classList.add("checked");
  };

  _switchAllToChecked() {
    this.notificationTargets.forEach(el => {
      this._checkNotification(el);
    });
  }

  _loadingBtnOn() {
    this.checkAllBtnTarget.classList.add("disabled");
    this.checkAllBtnTarget.innerHTML =
      "<i class='fas fa-circle-notch fa-spin'></i> Mise à jour en cour...";
  }

  _loadingBtnOff() {
    this.checkAllBtnTarget.classList.remove("disabled");
    this.checkAllBtnTarget.innerText = this.checkAllBtnText;
  }

  _hideCheckAllBtn() {
    this.checkAllBtnTarget.style.display = "none";
  }

  connect() {
    if (this.hasCheckAllBtnTarget)
      this.checkAllBtnText = this.checkAllBtnTarget.innerText;
  }

  checkAll(event) {
    event.preventDefault();

    const url = this.data.get("check-all-url");

    Rails.ajax({
      url,
      type: "PATCH",
      beforeSend: xhr => {
        this._loadingBtnOn();
        xhr.send();
      },
      success: () => {
        this._loadingBtnOff();
        this._hideCheckAllBtn();
        this._switchAllToChecked();
      }
    });
  }

  updateToChecked(event) {
    const target = event.target.closest(".notification-card");
    const url = target.dataset.notificationUrl;

    Rails.ajax({
      url,
      type: "PATCH",
      success: () => {
        this._checkNotification(target);
      }
    });
  }
}

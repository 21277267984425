import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "themeCheckbox",
    "subThemeCheckbox",
    "lessonCheckbox",
    "selectedCounter",
  ];

  selectedCount = 0;

  _changeCheckboxesState(target, parent) {
    target
      .closest(parent)
      .querySelectorAll('input[type="checkbox"]')
      .forEach((el) => (el.checked = target.checked));
  }

  _updateCount() {
    this.selectedCounterTarget.innerText = this.selectedCount;
  }

  _checkTargetsState(target, checkboxes) {
    target.checked = checkboxes.filter((el) => !el.checked).length === 0;
  }

  connect() {
    this._updateCount();
  }

  handleCheckTheme({ target }) {
    this._changeCheckboxesState(target, ".card");
  }

  handleCheckSubTheme({ target }) {
    this._changeCheckboxesState(target, "li");
    this._checkTargetsState(
      this.themeCheckboxTarget,
      this.subThemeCheckboxTargets
    );
  }

  handleCheckLesson({ target }) {
    const subTheme = target.closest(".sub-theme");
    const subThemeCheckbox = subTheme.querySelector(".sub-theme-title");
    const targets = Array.from(subTheme.querySelectorAll(".lesson-title"));
    this._checkTargetsState(subThemeCheckbox, targets);
    this._checkTargetsState(
      this.themeCheckboxTarget,
      this.subThemeCheckboxTargets
    );
  }

  refreshCount() {
    this.selectedCount = this.lessonCheckboxTargets.filter(
      (el) => el.checked
    ).length;
    this._updateCount();
  }
}

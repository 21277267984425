import {Controller} from "stimulus";
import $ from "jquery";
import Rails from "rails-ujs";

import initMultiSelect from "../components/multiselect";

export default class extends Controller {
    static targets = [
        "productRanges",
        "companies",
        "groupings",
        "productTypes",
        "products",
        "submitButton"
    ];

    static values = {
        autofill: String,
    }

    initialize() {
        this.autofillValue = JSON.parse(this.data.get("autofillValue"))
        if (this.autofillValue) {
            $('#import-file-modal-formeo').modal('show');
        }
    }

    fillInput(inputTarget, variable) {
        $(inputTarget).val(this.autofillValue[variable]).trigger('change').trigger('select2:select');
    }

    _getValues(target) {
        return $(target)
            .select2("data")
            .map((el) => el.id);
    }

    _productRangesParam() {
        return `productrange_ids=${this._getValues(this.productRangesTarget)}`;
    }

    _companiesParam() {
        return `company_ids=${this._getValues(this.companiesTarget)}`;
    }

    _productTypesParam() {
        return `p_types=${this._getValues(this.productTypesTarget)}`;
    }

    _populateSelectField(target, options, {disableIfEmpty}) {
        const selectedValues = this._getValues(target);
        target.innerHTML = "";
        options.forEach((el) => {
            if (selectedValues.includes(el.value)) el.selected = true;
            target.insertAdjacentElement("beforeend", el);
        });
        if (options.length === 1 && disableIfEmpty) return (target.disabled = true);
        target.disabled = false;
    }

    _parse_options(response) {
        let options = []
        if (response != '') {
            options = Array.from(response.querySelectorAll("option"));
        }
        return options
    }

    _fetchCompanies() {
        return new Promise((resolve) => {
            Rails.ajax({
                type: "GET",
                url: `/companies/for_select_formeo?${this._productRangesParam()}`,
                success: (response) => {
                    this._populateSelectField(this.companiesTarget, this._parse_options(response), {
                        disableIfEmpty: true,
                    });
                    resolve();
                }
            });
        });
    }

    _fetchGroupings() {
        return new Promise((resolve) => {
            Rails.ajax({
                type: "GET",
                url: `/groupings/for_select?${this._productRangesParam()}&${this._companiesParam()}`,
                success: (response) => {
                    this._populateSelectField(this.groupingsTarget, this._parse_options(response), {
                        disableIfEmpty: true,
                    });
                    resolve();
                },
            });
        });
    }

    _fetchProducts() {
        Rails.ajax({
            type: "GET",
            url: `/products/for_select?${this._productRangesParam()}&${this._productTypesParam()}`,
            success: (response) => {
                this._populateSelectField(this.productsTarget, this._parse_options(response), {
                    disableIfEmpty: true,
                });
            },
        });
    }

    _resetField(field) {
        $(field).val(null);
        field.options.forEach((option) => (option.selected = false));
        $(field).trigger("change");
    }

    _handleProductTypesField() {
        const values = this._getValues(this.productRangesTarget);

        if (values.length === 0) {
            this._resetField(this.productTypesTarget);
            this.productTypesTarget.disabled = true;
        } else {
            this.productTypesTarget.disabled = false;
        }
    }

    _handleButtonState() {
        const groupingIds = this._getValues(this.groupingsTarget);
        const productIds = this._getValues(this.productsTarget);

        if (groupingIds.length > 0 && productIds.length > 0) {
            this.submitButtonTarget.disabled = false;
        } else {
            this.submitButtonTarget.disabled = true;
        }
    }

    _handleCertificationsSelect() {
        this._fetchCompanies()
            .then(() => {
                if (this.autofillValue) {
                    this.fillInput(this.companiesTarget, 'company');
                }
            });
        this._fetchGroupings().then()
        this._handleProductTypesField();
        this._fetchProducts();
        this._handleButtonState();
    }

    _handleCertificationsUnselect() {
        this._fetchCompanies().then();
        this._fetchGroupings().then();
        this._handleProductTypesField();
        this._fetchProducts();
        this._handleButtonState();
    }

    _handleCompaniesSelect() {
        this._fetchGroupings().then(() => {
            if (this.autofillValue) {
                this.fillInput(this.groupingsTarget, 'grouping');
            }
        });
        this._handleButtonState();
    }

    _handleCompaniesUnselect() {
        this._fetchGroupings().then();
        this._handleButtonState();
    }

    _handleProductTypesSelect() {
        this._fetchProducts();
        this._handleButtonState();
    }

    _handleProductTypesUnselect() {
        this._fetchProducts();
        this._handleButtonState();
    }

    _handleGroupingsSelect() {
        this._handleButtonState();
    }

    _handleGroupingsUnselect() {
        this._handleButtonState();
    }

    _handleProductsSelect() {
        this._handleButtonState();
    }

    _handleProductsUnselect() {
        this._handleButtonState();
    }

    _initField({target, placeholder, callbacks}) {
        initMultiSelect(target, {placeholder});
        if (!callbacks) return;

        $(target).on("select2:select", () => callbacks.select());

        $(target).on("select2:unselect", () => callbacks.unselect());
    }

    _resetForm() {
        const inputs = [
            {
                input: this.productRangesTarget,
                disable: false,
            },
            {
                input: this.companiesTarget,
                disable: true,
            },
            {
                input: this.groupingsTarget,
                disable: true,
            },
            {
                input: this.productTypesTarget,
                disable: true,
            },
            {
                input: this.productsTarget,
                disable: true,
            },
        ];

        inputs.forEach(({input, disable}) => {
            this._resetField(input);
            input.disabled = disable;
        });
    }

    connect() {
        const fieldOptions = [
            {
                target: this.productRangesTarget,
                placeholder: "Sélectionnez une certification.",
                callbacks: {
                    select: this._handleCertificationsSelect.bind(this),
                    unselect: this._handleCertificationsUnselect.bind(this),
                },
            },
            {
                target: this.companiesTarget,
                placeholder: "Sélectionnez une société.",
                callbacks: {
                    select: this._handleCompaniesSelect.bind(this),
                    unselect: this._handleCompaniesUnselect.bind(this),
                },
            },
            {
                target: this.groupingsTarget,
                placeholder: "Sélectionnez un regroupement.",
                callbacks: {
                    select: this._handleGroupingsSelect.bind(this),
                    unselect: this._handleGroupingsUnselect.bind(this),
                },
            },
            {
                target: this.productTypesTarget,
                placeholder: "Sélectionnez un type de produit.",
                callbacks: {
                    select: this._handleProductTypesSelect.bind(this),
                    unselect: this._handleProductTypesUnselect.bind(this),
                },
            },
            {
                target: this.productsTarget,
                placeholder: "Sélectionnez un produit.",
                callbacks: {
                    select: this._handleProductsSelect.bind(this),
                    unselect: this._handleProductsUnselect.bind(this),
                },
            },
        ];

        fieldOptions.forEach((options) => this._initField(options));

        $("#import-file-modal-formeo").on(
            "hidden.bs.modal",
            (() => this._resetForm()).bind(this)
        );

        $("#import-file-modal-formeo").on("shown.bs.modal", () => {
            if (this.autofillValue) {
                this.fillInput(this.productRangesTarget, 'productrange');
            }
        });
    }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "sentenceOutput",
    "hiddenConsumptionInput",
    "consumptionItem",
    "submitBtn",
    "productSelect",
    "inscriptionSelect"
  ];

  consumptionPresence = false;

  defaultBtnText = "";

  noConsumptionsBtnText = "";

  selectedInscriptionId = null;

  selectedProductId = null;

  _removeAllActiveClasses() {
    this.consumptionItemTargets.forEach(element => {
      element.classList.remove("active");
    });
  }

  _populateHiddenConsumptionInput({ dataset: { consumptionId } }) {
    this.hiddenConsumptionInputTarget.value = consumptionId;
  }

  _buildSentence = ({ dataset: { productName, duration } }) => {
    return `La consommation ${productName} va être prolongée de ${duration}`;
  };

  _enableBtn = () => {
    this.submitBtnTarget.disabled = false;
  };

  _disableBtn() {
    this.submitBtnTarget.disabled = true;
  }

  _initBtnText() {
    if (this.consumptionPresence) {
      this.submitBtnTarget.innerText = this.defaultBtnText;
      return;
    }

    this.submitBtnTarget.innerText = this.noConsumptionsBtnText;
  }

  _updateSelectedInscription() {
    this.selectedInscriptionId = this.inscriptionSelectTarget.value;
  }

  _updateSelectedProduct() {
    this.selectedProductId = this.productSelectTarget.value;
  }

  _updateBtn() {
    if (this.selectedInscriptionId && this.selectedProductId) {
      this._enableBtn();
      return;
    }

    this._disableBtn();
  }

  _initBtn() {
    this._initBtnText();
    this._updateBtn();
  }

  updateProduct() {
    this._updateSelectedProduct();
    this._updateBtn();
  }

  updateInscription() {
    this._updateSelectedProduct();
    this._updateBtn();
  }

  selectConsumption(event) {
    this._removeAllActiveClasses();
    const link = event.target.closest("a");
    link.classList.add("active");
    this._populateHiddenConsumptionInput(link);
    this.sentenceOutputTarget.innerText = this._buildSentence(link);
    this._enableBtn();
  }

  connect() {
    this.consumptionPresence = this.data.get("consumption-presence") === "true";
    this.defaultBtnText = this.data.get("default-btn-text");
    this.noConsumptionsBtnText = this.data.get("no-consumptions-btn-text");

    if (this.hasInscriptionSelectTarget && this.hasProductSelectTarget) {
      this._updateSelectedInscription();
      this._updateSelectedProduct();
    }

    this._initBtn();
  }
}

import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["actionText", "collapse"];

  isShown = false;

  _changeActionText() {
    if (this.isShown) {
      this.actionTextTarget.innerText = this.data.get("hide");
      return;
    }

    this.actionTextTarget.innerText = this.data.get("show");
  }

  connect() {
    if (this.hasActionTextTarget) {
      this.isShown = this.data.get("shown-by-default") === "true";
      if (this.isShown) this.collapseTarget.classList.add("show");
      this._changeActionText();
    }
  }

  toggleList(event) {
    event.preventDefault();
    $(this.collapseTarget).collapse("toggle");
    this.isShown = !this.isShown;
    this._changeActionText();
  }
}

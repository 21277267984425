import { Controller } from "stimulus";
import $ from "jquery";

import initMultiSelect from "../components/multiselect";

export default class extends Controller {
  static targets = [
    "companies",
    "submitButton"
  ];

  _getValues(target) {
    return $(target)
        .select2("data")
        .map((el) => el.id);
  }

  _populateSelectField(target, options, { disableIfEmpty }) {
    const selectedValues = this._getValues(target);
    target.innerHTML = "";
    options.forEach((el) => {
      if (selectedValues.includes(el.value)) el.selected = true;
      target.insertAdjacentElement("beforeend", el);
    });
    if (options.length === 1 && disableIfEmpty) return (target.disabled = true);
    target.disabled = false;
  }

  _parse_options(response) {
    let options = []
    if (response != '') { options = Array.from(response.querySelectorAll("option")); }
    return options
  }

  _resetField(field) {
    $(field).val(null);
    field.options.forEach((option) => (option.selected = false));
    $(field).trigger("change");
  }

  _handleButtonState() {
    const companyIds = this._getValues(this.companiesTarget);

   this.submitButtonTarget.disabled = companyIds.length === 0;
  }

  _handleCompaniesSelect() {
    this._handleButtonState();
  }

  _handleCompaniesUnselect() {
    this._handleButtonState();
  }

  _initField({ target, placeholder, callbacks }) {
    initMultiSelect(target, { placeholder });

    if (!callbacks) return;

    $(target).on("select2:select", () => callbacks.select());

    $(target).on("select2:unselect", () => callbacks.unselect());
  }

  _resetForm() {
    const inputs = [
      {
        input: this.companiesTarget,
        disable: true
      }
    ];

    inputs.forEach(({ input, disable }) => {
      this._resetField(input);
      input.disabled = disable;
    });
  }

  connect() {
    const fieldOptions = [
      {
        target: this.companiesTarget,
        placeholder: "Sélectionnez une société.",
        callbacks: {
          select: this._handleCompaniesSelect.bind(this),
          unselect: this._handleCompaniesUnselect.bind(this),
        },
      }
    ];

    fieldOptions.forEach((options) => this._initField(options));

    $("#manager-imports-template-file-modal").on(
        "hidden.bs.modal",
        (() => this._resetForm()).bind(this)
    );
  }
}

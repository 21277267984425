import { Controller } from "stimulus";
import Rails from "rails-ujs";

export default class extends Controller {
  static targets = ["lessonsForm", "productRangeSelect", "lessonsContainer", "productsContainer", "submitBtn"];

  _resetLessonsContainer() {
    this.lessonsContainerTarget.innerHTML = "";
  }

  _resetProductsContainer() {
    this.productsContainerTarget.innerHTML = "";
  }

  _loadingOn() {
    this._resetLessonsContainer();
    this.lessonsContainerTarget.innerHTML =
      "<div class='text-center'><i class='fas fa-circle-notch fa-spin'></i> Chargement en cours...</div>";
  }

  _buildErrorElement(status) {
    const el = document.createElement("div");
    el.id = "products";
    el.classList.add("text-center");

    const strong = document.createElement("strong");
    strong.classList.add("text-danger");

    const icon = document.createElement("i");
    icon.classList.add("fas", "fa-exclamation-triangle");

    strong.appendChild(icon);
    strong.insertAdjacentText(
      "beforeend",
      ` Une erreur est survenue: ${status}`
    );
    el.appendChild(strong);
    return el;
  }

  _updateDom(target, element) {
    target.insertAdjacentElement(
      "afterbegin",
      element
    );
  }

  _updateLessonsContainer(element) {
    this._resetLessonsContainer();
    this._resetProductsContainer();
    this._updateDom(this.lessonsContainerTarget, element);
  }

  _updateProductContainer(element) {
    this._resetProductsContainer();
    this._updateDom(this.productsContainerTarget, element);
  }

  fetchCourses() {
    if (!this.productRangeSelectTarget.value) {
      this._resetLessonsContainer();
      this._resetProductsContainer();
    } else {
      Rails.ajax({
        type: "GET",
        url: `/courses/lessons/for_product_search?productrange_id=${this.productRangeSelectTarget.value}`,
        beforeSend: (xhr) => {
          this._loadingOn();
          xhr.send();
        },
        success: (response) => {
          this._updateLessonsContainer(response.getElementById("lessons"));
        },
        error: () => {
          this._updateLessonsContainer(this._buildErrorElement("Internal server error"));
        },
      });
    }
  }

  handleSuccess({detail: [data]}) {
    this._updateProductContainer(data.getElementById("products"));
  }

  handleError({detail: [data, status]}) {
    this._updateProductContainer(this._buildErrorElement(status));
  }
}

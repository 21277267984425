import { Controller } from "stimulus";
import Rails from "rails-ujs";

export default class extends Controller {
  static targets = ["groupings", "products", "productranges"];

  _populateSelectField(target, options) {
    const select = this[target];
    select.innerHTML = "";
    options.forEach(el => select.insertAdjacentElement("beforeend", el));
    select.disabled = false;
  }

  _callOurs(url, target) {
    Rails.ajax({
      type: "GET",
      url,
      success: response => {
        const options = Array.from(response.querySelectorAll("option"));
        this._populateSelectField(target, options);
      }
    });
  }

  _fetchGroupingsOptions(productRangeId) {
    const companyId = this.data.get("company-id");
    const url = `/productranges/${productRangeId}/groupings_for_select/${companyId}`;
    this._callOurs(url, "groupingsTarget");
  }

  _fetchProductsOptions(productRangeId) {
    const url = `/productranges/${productRangeId}/products_for_select`;
    this._callOurs(url, "productsTarget");
  }

  _disableFields() {
    ["groupings", "products"].forEach(fieldName => {
      const target = this[`${fieldName}Target`];
      target.innerHTML = "";
      target.disabled = true;
    });
  }

  fetchGroupingsAndProducts() {
    const productRangeId = this.productrangesTarget.value;
    if (productRangeId === "") return this._disableFields();
    this._fetchGroupingsOptions(productRangeId);
    this._fetchProductsOptions(productRangeId);
    return true;
  }
}

import $ from "jquery";

import "select2";
import "select2/dist/css/select2.min.css";
import "@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css";

const initMultiSelect = (selector, options) => {
  return $(selector).select2({
    theme: "bootstrap4",
    ...options
  });
};

window.initMultiSelect = initMultiSelect;

export default initMultiSelect;

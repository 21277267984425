import ActionCable from "actioncable";

const showCounter = counter => {
  if (parseInt(counter.innerHTML, 10) > 0) {
    counter.classList.remove("invisible");
  }
};

const showAlert = () => {
  document.querySelectorAll(".alert").forEach(el => {
    const { style, classList } = el;
    if (!el.classList.contains("hidden")) return;
    style.top = "0px";
    classList.remove("hidden");
    setTimeout(() => {
      style.opacity = "0";
      el.addEventListener(
        "transitionend",
        () => {
          el.remove();
        },
        false
      );
    }, 10000);
  });
};

const addToCounter = () => {
  const counter = document.querySelector("#notification-icon p");
  const newCount = parseInt(counter.innerHTML, 10) + 1;
  counter.innerHTML = newCount;
  counter.classList.remove("invisible");
};

const initNotificationChannel = () => {
  showAlert();
  const counter = document.getElementById("notification-counter");
  if (!counter) return;

  showCounter(counter);
  const container = document.querySelector(".notification-container");
  const { userId } = container.dataset;
  if (!userId) return;

  const cable = ActionCable.createConsumer();
  cable.subscriptions.create(
    {
      channel: "NotificationsChannel"
    },
    {
      received: ({ title, alert }) => {
        if (title) addToCounter();
        container.insertAdjacentHTML("afterbegin", alert);
        setTimeout(() => showAlert(), 10);
      }
    }
  );

  setInterval(() => {
    showAlert();
  }, 100);
};

export default initNotificationChannel;

import { Controller } from "stimulus";
import moment from "moment";

export default class extends Controller {
  static targets = ["endDateOutput", "durationInput", "submitBtn"];

  endDateDefaultText = "";

  _computeEndDate() {
    const startDate = moment(
      this.durationInputTarget.dataset.date,
      "DD/MM/YYYY"
    );
    const stringDuration = this.durationInputTarget.value.split("_").pop();
    if (stringDuration === "") return this.endDateDefaultText;
    if (stringDuration === "EOY")
      return moment()
        .endOf("year")
        .format("DD/MM/YYYY");

    const duration = moment.duration(stringDuration);
    return startDate.add(duration).format("DD/MM/YYYY");
  }

  _updateSubmitBtnState() {
    if (this.durationInputTarget.value === "") {
      this.submitBtnTarget.disabled = true;
      return;
    }
    this.submitBtnTarget.disabled = false;
  }

  updateDate() {
    this.endDateOutputTarget.innerText = this._computeEndDate();
    this._updateSubmitBtnState();
  }

  connect() {
    this.endDateDefaultText = this.endDateOutputTarget.innerText;
    this._updateSubmitBtnState();
  }
}
